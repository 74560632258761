<template>
  <b-row>
    <b-col>
      <b-card v-if="where" title="Metering report">
        <blueprint-data
          :where="where" api-route-path="reports/metering" :fields="fields" no-actions no-new no-edit
          limit-per-page="10000"
          no-limit
        >
          <template v-slot:search>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Utility Meter Type" label-for="typeId">
              <blueprint-search-input id="typeId" :key="typeId" v-model="typeId" placeholder="Type to search" :api-route-path="`inventory/utilitymetertypes`" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Property" label-for="propertyId">
              <blueprint-search-input id="propertyId" :key="propertyId" v-model="propertyId" placeholder="Type to search" :api-route-path="`inventory/properties`" />
            </b-form-group>
            <date-range v-model="range" no-auto-disable />
          </template>
          <template v-slot:startDate="{ item }">
            {{ item.startDate | moment('LLL') }}
          </template>
          <template v-slot:endDate="{ item }">
            {{ item.endDate | moment('LLL') }}
          </template>
          <template v-slot:meter="{ item }">
            {{ item.meter.name }} ({{ item.meter.type.name }})
          </template>
          <template v-slot:startValue="{ item }">
            {{ item.startValue }} {{ item.meter.type.unit }}
          </template>
          <template v-slot:endValue="{ item }">
            {{ item.endValue }} {{ item.meter.type.unit }}
          </template>
          <template v-slot:usage="{ item }">
            {{ parseFloat(item.endValue) - parseFloat(item.startValue) }} {{ item.meter.type.unit }}
          </template>
          <template v-slot:property="{ item }">
            {{ item.meter.unit.property.name }}
          </template>
          <template v-slot:unit="{ item }">
            {{ item.meter.unit.name }}
          </template>
        </blueprint-data>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
const now = new Date();
const lastday  = new Date(now.getFullYear(), now.getMonth(), 0);
const firstday = new Date(lastday.getFullYear(), lastday.getMonth(), 0);

import DateRange from '../../../components/date-range.vue';
export default {
  components: {
    DateRange
  },
  data () {
    return {
      propertyId: undefined,
      typeId: undefined,
      range: {
        start: firstday,
        end: lastday
      },
      where: undefined,
      fields: [
        {
          key: 'property',
          slot: true,
        },
        {
          key: 'unit',
          label: 'Unit',
          slot: true,
        },
        {
          key: 'meter',
          label: 'Meter',
          slot: true,
        },
        {
          key: 'startDate',
          label: 'Start date',
          slot: true,
        },
        {
          key: 'startValue',
          label: 'Start readout',
          slot: true,
        },
        {
          key: 'endDate',
          label: 'End date',
          slot: true,
        },
        {
          key: 'endValue',
          label: 'End readout',
          slot: true,
        },
        {
          key: 'usage',
          slot: true,
        },
      ],
    };
  },
  watch: {
    propertyId () {
      this.buildWhere();
    },
    typeId () {
      this.buildWhere();
    },
    range () {
      this.buildWhere();
    }
  },
  mounted () {
    this.buildWhere();
  },
  methods: {
    parse (name, arr) {
      const ret = {};
      if (Array.isArray(arr) && arr.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        ret[name] = arr[0];
      } else if (Array.isArray(arr) && arr.length > 1) {
        ret[name] = { $in: arr };
      } else if (arr) {
        ret[name] = arr;
      }
      return ret;
    },
    buildWhere () {
      const {
        propertyId, typeId, range
      } = this;

      this.where = {
        ...this.parse('meter.unit.propertyId', propertyId),
        ...this.parse('meter.typeId', typeId),
        ...{
          date: { $between: [ range.start.toISOString(),range.end.toISOString() ] },
        }
      };
    },
  },
};
</script>
